

import { callAPI } from '@/utils/requests'
import { defineComponent } from 'vue'
import { LineChart } from 'vue-chart-3'
import { Chart, registerables } from 'chart.js'
import InlineSvg from 'vue-inline-svg'

Chart.register(...registerables)

export default defineComponent(
  {
    components: {
      LineChart,
      InlineSvg
    },
    data (): any {
      return {
        measurements: null,
        graphsData: {},
        activeFilter: 3
      }
    },
    methods: {
      computeGaugeValue (temperature: number, minValue: number, maxValue: number): number {
        // 180 is max
        const tempRange = maxValue - minValue
        const degreeStep = 180 / tempRange
        const diffToMin = temperature - minValue

        return degreeStep * diffToMin
      },
      filterGraph (hoursRange: number) {
        this.activeFilter = hoursRange

        const now: number = Math.floor(new Date().getTime() / 1000)
        const threshold = now - (hoursRange * 60 * 60) // seconds

        this.measurements.forEach((deviceData: any) => {
          const labels: string[] = []
          const temperatureData: number[] = []
          const humidityData: number[] = []

          deviceData.measurements.forEach((measurement: any) => {
            if (parseInt(measurement.millisecondsDatetime) >= threshold) {
              labels.push(measurement.formattedDatetime)
              // set data and round it
              // temperatureData.push(Math.round(measurement.temperature * 2) / 2) // round to 0.5
              temperatureData.push(measurement.temperature)
              humidityData.push(Math.round(measurement.humidity))
            }
          })

          this.graphsData[deviceData.device.id] = {}

          const defaultGraphSettings: any = {
            labels: labels,
            datasets: [
              {
                borderColor: '#38d0ad',
                cubicInterpolationMode: 'monotone'
              }
            ]
          }

          const temperatureGraphSettings = JSON.parse(JSON.stringify(defaultGraphSettings))
          temperatureGraphSettings.datasets[0].label = 'Teplota'
          temperatureGraphSettings.datasets[0].data = temperatureData
          this.graphsData[deviceData.device.id].temperature = temperatureGraphSettings

          const humidityGraphSettings = JSON.parse(JSON.stringify(defaultGraphSettings))
          humidityGraphSettings.datasets[0].label = 'Vlhkost'
          humidityGraphSettings.datasets[0].data = humidityData
          this.graphsData[deviceData.device.id].humidity = humidityGraphSettings
        })
      }
    },
    mounted (): void {
      callAPI('measurements').then((data: any) => {
        this.measurements = data.data
        this.filterGraph(3)
      })
    }
  })
