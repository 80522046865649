class RequestException extends Error {
  data: any;
  response: any;

  constructor (message: string, data: any, response: any) {
    super(message)
    this.message = message
    this.data = data
    this.response = response
    this.name = 'RequestException'
  }
}

export async function callAPI (
  endpoint: string,
  data: Record<string, unknown> = {},
  method = 'GET'
): Promise<Record<string, unknown>> {
  const headers: { [k: string]: string } = {
    'content-type': 'application/json',
    'X-AUTH-TOKEN': process.env.VUE_APP_API_TOKEN
  }

  const fetchConf: { [k: string]: unknown } = {
    method: method,
    headers: new Headers(headers)
  }

  if (data && Object.keys(data).length !== 0) {
    fetchConf.method = 'POST'
    fetchConf.body = JSON.stringify(data)
  }

  let isError = false
  let rawResponse: Response
  return await fetch(process.env.VUE_APP_API_BASE_URL + endpoint, fetchConf)
    .then((response) => {
      rawResponse = response
      if (!response.ok) {
        isError = true
      }
      return response
    })
    .then((response) => response.json())
    .then((responseData) => {
      if (isError) { // throw error with already processed (json) data
        throw new RequestException(`Failed with HTTP code ${rawResponse.status}:  ${rawResponse.statusText}`, responseData, rawResponse)
      }

      return responseData
    })
}
